import Pusher from 'pusher-js'
import axios from 'axios'
import swal from 'sweetalert2/dist/sweetalert2'
import { find, forEach, extend, omitBy, isNil, isPlainObject } from 'lodash'
import { parseFullName } from 'parse-full-name'
import { route } from 'ziggy'
import filesize from '@/libs/filesize.js'
import { Ziggy } from './routes.js'
import './counters.js'
import Vue from 'vue'

window.Munio = Munio

//
// Ziggy
//

window.Ziggy = Ziggy
const currentRoute = Munio.config.route
Ziggy.url = currentRoute.base.url
Object.assign(window.Ziggy.defaults, currentRoute.defaults, currentRoute.params)

Munio.route = route

/**
 * Set page title
 *
 * @param {string} title
 */
Munio.setTitle = function (title) {
  document.title = `${title} - ${Munio.config.sitename}`
  document.querySelector('.header-title__title').textContent = title
}

//
// Colors
//

Munio.chartColors = [
  '#303F9F',
  '#1976D2',
  '#0097A7',
  '#00796B',
  '#388E3C',
  '#AFB42B',
  '#FBC02D',
  '#F57C00',
  '#D32F2F',
  '#C2185B',
  '#7B1FA2',
  '#607D8B',
  '#757575',
  '#795548',
]

/**
 * Name parser
 *
 * @param {string} name
 * @returns {{first: string, last: string}}
 */
Munio.parseName = (name) => {
  const { first, middle, last } = parseFullName(name.trim())

  return {
    first: [first, middle].join(' ').trim(),
    last: last,
  }
}

/**
 * Attach behaviors
 */
Munio.attachBehaviors = (context, config) => {
  context = context || document
  config = config || Munio.config

  // Execute all of them.
  forEach(Munio.behaviors, (behavior) => {
    // Create a new instance of the behavior to
    // avoid overidden functions or variables.
    const instance = extend({}, behavior)

    if (typeof instance.attach !== 'function') {
      return
    }

    if (instance.selector) {
      // Skip behavior if the element is not found
      if ($(instance.selector, context).length === 0) {
        return
      }
    }

    // Bind the "attach" function to
    // the behavior and run it.
    instance.attach.bind(instance)
    instance.attach(context, config)
  })
}

/**
 * Global handler for the data object from JSON responses.
 *
 * @param {Object} data
 * @return  bool
 */
Munio.handleJSONResponseData = function (data) {
  if (!data) {
    return true
  }

  if (Array.isArray(data.toasts)) {
    data.toasts.forEach((toast) => Munio.Notifications.show(toast))
  }

  if (data.redirect) {
    if (window.location.href === data.redirect) {
      window.location.reload()
    } else {
      window.location = data.redirect
    }

    return false
  }

  if (data.refresh) {
    window.location.reload()
  }

  return true
}

Munio.config.csv = {
  header: true,
  delimiter: 'comma',
}

Munio.downloadCSV = async function (baseUrl, options = {}, urlParams = window.location.search) {
  const settings = Munio.storage.getWithDefault('csvSettings', Munio.config.csv)

  if (typeof options.header === 'boolean') {
    settings.header = options.header
  }
  if (typeof options.delimiter === 'string') {
    settings.delimiter = options.delimiter
  }
  Munio.storage.set('csvSettings', settings)

  const parameters = Munio.urlParams(urlParams)
  parameters.set('export', 'csv')
  parameters.set('csv[header]', settings.header)
  parameters.set('csv[delimiter]', settings.delimiter)
  if (options.id) {
    parameters.set('csv[id]', options.id)
  }

  $(window).bind('beforeunload', window.blockNavigate)

  try {
    const { data, headers } = await Munio.$http.get(baseUrl, parameters, {}, { responseType: 'blob' })
    const _mime = headers['content-type'] || 'text/csv;charset=utf-8'
    const _filename = window.extractDispositionFilename(headers['content-disposition'])
    window.downloadFile(data, _filename, _mime)
  } catch (err) {
    await Munio.alert(err.toString())
  } finally {
    $(window).unbind('beforeunload', window.blockNavigate)
  }
}

/**
 * Get absolute url to asset.
 *
 * @param  {string}  path
 * @return string
 */
Munio.url = function (path) {
  if (path === undefined) {
    return new URL(window.location)
  }

  return new URL(Munio.config.baseurl + '/' + path)
}

Munio.objectFilter = function (obj) {
  const newObj = {}

  Object.keys(obj).forEach((key) => {
    if (isPlainObject(obj[key])) {
      const subObj = Munio.objectFilter(obj[key])
      if (Object.keys(subObj).length) {
        newObj[key] = subObj
      }
    } else {
      newObj[key] = obj[key]
    }
  })

  return omitBy(newObj, isNil)
}

/**
 * @param {HTMLFormElement|URL|URLSearchParams|Object|string} params
 * @returns URLSearchParams
 */
Munio.urlParams = function (params = window.location.search) {
  if (params instanceof URL) {
    params = params.searchParams
  }

  if (params instanceof HTMLFormElement) {
    params = $(params).serialize()
  }

  if (params instanceof URLSearchParams) {
    params = params.toString()
  } else if (params === null) {
    params = undefined
  } else if (isPlainObject(params)) {
    params = $.param(Munio.objectFilter(params))
  }

  return new URLSearchParams(params)
}

/**
 *
 * @param {HTMLFormElement|URL|URLSearchParams|Object|string} params
 * @returns {string}
 */
Munio.urlParamsString = function (params = window.location.search) {
  return Munio.urlParams(params).toString()
}

/**
 * Get the flag emoji for a country.
 */
Munio.flag = function (country) {
  return country.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
}

/**
 * Get the country model
 */
Munio.country = function (iso) {
  return Munio.config.countries.find((country) => country.iso === iso.toLowerCase())
}

/**
 * Get all the visible country models (sorted)
 */
Munio.countries = function (local = true) {
  const sortKey = local ? 'i18n' : 'name'

  return Munio.config.countries
    .filter((country) => country.isVisible)
    .sort((a, b) => a[sortKey].localeCompare(b[sortKey], Munio.config.i18n.language))
}

/**
 * Get all the languages (sorted)
 *
 * @param {boolean} local
 * @param {boolean} translated
 * @returns {Array<{
 *  key: string,
 *  language: string,
 *  country: string,
 *  locale: string,
 *  title: string,
 *  name: string,
 *  flag: string,
 *  translated: boolean,
 * }>}
 */
Munio.languages = function (local = true, translated = true) {
  const sortKey = local ? 'name' : 'title'

  return Munio.config.languages
    .filter((l) => (translated ? l.translated : true))
    .sort((a, b) => a[sortKey].localeCompare(b[sortKey], Munio.config.i18n.language))
}

/**
 * Websocket
 */
let pusher

Munio.Websocket = function () {
  if (pusher) return pusher

  const { key, options } = Munio.config.websocket

  pusher = new Pusher(key, options)

  pusher.private = function (channel) {
    return this.subscribe('private-' + channel)
  }

  pusher.presence = function (channel) {
    return this.subscribe('presence-' + channel)
  }

  axios.interceptors.request.use((config) => {
    if (pusher.connection.socket_id) {
      config.headers['X-Socket-Id'] = pusher.connection.socket_id
    }

    return config
  })

  if (typeof jQuery.ajax !== 'undefined') {
    jQuery.ajaxPrefilter((options, originalOptions, xhr) => {
      if (pusher.connection.socket_id) {
        xhr.setRequestHeader('X-Socket-Id', pusher.connection.socket_id)
      }
    })
  }

  if (isDevMode) {
    pusher.connection.bind_global(function () {
      console.debug('[PUSHER]', ...arguments)
    })
  }

  return pusher
}

/**
 * Language getter
 *
 * @param language
 * @return object
 */
Munio.language = function (language) {
  return (
    find(this.config.languages, { key: language }) ||
    find(this.config.languages, { locale: language }) ||
    find(this.config.languages, { language })
  )
}

/**
 * Show a flash message
 *
 * @type {{message, success, info}}
 * @link https://limonte.github.io/sweetalert2/
 */
Munio.Flash = (function () {
  const parseArguments = function (text, options = {}) {
    if (typeof text === 'object') {
      options = text
      text = null
    }

    return { text, options }
  }

  const message = function (title, text, options = {}, defaultOptions = {}) {
    ;({ text, options } = parseArguments(text, options))

    const globalOptions = {
      reverseButtons: true,
      buttonsStyling: false,
      allowEnterKey: false,
      cancelButtonClass: 'mdl-button mdl-js-button',
      cancelButtonText: trans('Cancel'),
      confirmButtonClass: 'mdl-button mdl-js-button mdl-button--raised mdl-button--primary',
      confirmButtonText: trans('OK'),
    }

    return swal(Object.assign({}, globalOptions, defaultOptions, options, { html: text, title }))
  }

  return {
    message,
    success: (title = trans('Success'), text = undefined, options = {}) => {
      return message(title, text, options, { type: 'success', showConfirmButton: false, timer: 1800 })
    },
    info: (title, text, options = {}) => {
      console.info(title, text)
      return message(title, text, options, { type: 'info' })
    },
    error: (title, text, options = {}) => {
      console.error(title, text)
      return message(title, text, options, { type: 'error' })
    },
    warning: (title, text, options = {}) => {
      console.warn(title, text)
      return message(title, text, options, { type: 'warning' })
    },
    question: (title, text, options = {}) => {
      return message(title, text, options, { type: 'question' })
    },
  }
})()

/**
 * Show a confirmation dialog
 *
 * @param {string} [title]
 * @param {string?} [text]
 * @param {object?} [options]
 * @returns {Promise<number|string|boolean>}
 */
Munio.confirm = async function (title = trans('Are you sure?'), text = undefined, options = {}) {
  const defaultOptions = {
    type: 'question',
    showCancelButton: true,
    confirmButtonText: trans('Confirm'),
  }

  const response = await Munio.Flash.message(title, text, Object.assign({}, defaultOptions, options))

  if (response.dismiss) {
    return false
  }

  return response.value ?? false
}

/**
 * Show an alert dialog
 *
 * @param title
 * @param text
 * @param options
 * @returns {Promise<boolean>}
 */
Munio.alert = async function (title, text, options = {}) {
  return (await Munio.Flash.info(title, text, options)).value || false
}

/**
 * Show a prompt dialog
 *
 * @param title
 * @param text
 * @param options
 * @returns {Promise<any>}
 */
Munio.prompt = async function (title, text, options = {}) {
  const defaultOptions = {
    type: 'question',
    input: 'text',
    showCancelButton: true,
  }

  return (await Munio.Flash.message(title, text, Object.assign({}, defaultOptions, options))).value
}

/**
 * @param id
 * @param data
 * @returns {Promise<Api12>}
 * @constructor
 */
Munio.Scorm12 = async function (id, data) {
  const Api = (await import('./scorm/Api12/index.js')).default

  console.log('Scorm12', Api)
  return new Api(id, data)
}

/**
 * @param id
 * @param data
 * @returns {Promise<Api2004>}
 * @constructor
 */
Munio.Scorm2004 = async function (id, data) {
  const Api = (await import('./scorm/Api2004/index.js')).default

  return new Api(id, data)
}

Munio.filesize = filesize

// Event bus
Munio.Events = new (class extends Vue {})()

export default Munio
