import { inject, ref, defineAsyncComponent, set, computed, watch } from 'vue'
import { some, findIndex, forEach, every, find, filter } from 'lodash'
import { defineStore } from 'pinia'
import { AxiosError, isAxiosError } from 'axios'
import { StripeError } from '@stripe/stripe-js'
import { CompanyBasicResourceDto, PaymentMethod, ShopCartItemResourceDto } from '@/munio/api/data'
import ShopCheckout, {
  ItemEnrollment,
  NewOrExistingUser,
  AuthenticatedCart,
  CardConfirmResponse,
} from '@/munio/api/ShopCheckout'
import { Step } from './Stepper/Types'
import { isValidEmail } from '@/munio/utils/index.js'
import { clearLocalStorage, getActiveStep, getStepsNumber, saveActiveStep, saveStepsNumber } from './utils'
import { trans } from '@/munio/i18n/index.js'
import { useCardStore } from './store.card'

export const useShopCheckoutStore = defineStore('shopCheckoutSteps', () => {
  const cardStore = useCardStore()
  const cart = ref(inject('cart') as AuthenticatedCart)
  const context = cart.value.context
  const user = computed(() => cart.value.user)
  const api: ShopCheckout = window.Munio.api.shop(cart.value.shop.slug, context).checkout
  const loading = ref<'processing' | boolean>(false)
  const alert = ref<string>()
  const alertType = ref<'danger' | 'success' | 'info' | 'warning'>('danger')
  const steps = ref<Step[]>([])
  const step = ref<Step['id']>('buyer')
  const enrolledUsers = ref<Record<number, NewOrExistingUser[]>>({})
  const enrolledUsersConflict = ref<
    | false
    | {
        item: number
        users: NewOrExistingUser[]
      }
  >(false)
  const paymentProcessed = ref(false)

  // Websocket
  const channel = Munio.Websocket().private('user.' + user.value.id)
  channel.bind('cart.confirmed', (payload: CardConfirmResponse) => {
    if (!context && payload.redirectUrl) {
      window.location.href = payload.redirectUrl
    }
  })

  const paymentMethod = ref<PaymentMethod['value']>(cart.value.paymentMethod.value)
  const paymentMethodForm = ref<{
    card: {
      valid: boolean
      confirmationTokenId?: string
    }
    invoice: {
      reference: string
      email: string
      address: {
        line1: string
        line2?: string
        city: string
        postcode?: string
        country: string
      }
    }
  }>({
    card: {
      valid: false,
    },
    invoice: {
      reference: '',
      email: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        postcode: '',
        country: '',
      },
    },
  })

  async function reloadCart() {
    try {
      loading.value = true
      const { data } = await api.get()
      cart.value = data.data
    } catch (err) {
      console.error(err)
    } finally {
      loading.value = false
    }
  }

  //
  // Buyer
  //

  const isSetCurrentAsEmployerChecked = ref(false)

  async function submitBuyer(stepId: string) {
    if (cart.value.company && isSetCurrentAsEmployerChecked.value) {
      await updateBuyer(cart.value.company)
    }

    let index = findIndex(steps.value, ['id', 'payment'])
    steps.value[index].completed = false

    isSetCurrentAsEmployerChecked.value = false

    next(stepId)
  }

  async function updateBuyer(company: CompanyBasicResourceDto) {
    const step = getStep('buyer')
    try {
      step.loading = true

      const response = await api.updateCompany(company, isSetCurrentAsEmployerChecked.value)

      cart.value = response.data.data
      updateInvoiceForm()
      submitBuyer(step.id)
    } catch (e) {
      console.error(e)
    } finally {
      step.loading = false
    }
  }

  //
  // Enrollement
  //

  const isItemEnrollmentRequiredFullfilled = computed(() => {
    return some(cart.value.items, (item) => item.product.requireRegistration && !isItemFullyEnrolled(item))
  })

  const isSelfEnrolledToAllItems = computed(() => {
    return every(cart.value.items, (item: any) => isSelfEnrolledToItem(item.id))
  })

  const enrollableItems = computed(() => {
    return filter(cart.value.items, (item: any) => item.product !== null && item.product !== undefined)
  })

  function setInitialEnrolledUserList() {
    forEach(cart.value.items, (item: ShopCartItemResourceDto) => {
      set(enrolledUsers.value, item.id, item.options?.participants ?? [])
    })
  }

  function hasItemEnrollmentRequired() {
    return some(cart.value?.items, (item) => item.product.requireRegistration)
  }

  // Step Enrollement
  function enrollSelfToAll() {
    forEach(cart.value.items, (item) => {
      if (!isSelfEnrolledToItem(item.id) && !isItemFullyEnrolled(item)) {
        enrolledUsers.value[item.id].push(user.value)
      }
    })
  }

  function unenrollSlefFromAll() {
    forEach(cart.value.items, (item) => {
      unenrollUserFromItem(item.id, user.value)
    })
  }

  function enrollSelfToItem(itemId: number) {
    if (!isSelfEnrolledToItem(itemId)) {
      enrolledUsers.value[itemId].push(user.value)
    }
  }

  function unenrollAllFromItem(itemId: number) {
    enrolledUsers.value[itemId] = []
  }

  function isSelfEnrolledToItem(itemId: number) {
    return find(enrolledUsers.value[itemId], (u: NewOrExistingUser) => u.email === user.value.email) !== undefined
  }

  function isItemFullyEnrolled(item: any) {
    return enrolledUsers.value[item.id].length === item.quantity && !item.isUnlimited
  }

  function areItemsFullyEnrolled() {
    return every(
      filter(cart.value.items, (item) => !item.isUnlimited),
      (item: any) => isItemFullyEnrolled(item),
    )
  }

  function isEnrolledSelfToAllDisabled() {
    return areItemsUnlimited() && areItemsFullyEnrolled()
  }

  function areItemsUnlimited() {
    return some(cart.value.items, (item) => item.isUnlimited)
  }

  function enrollUserToItem(itemId: number, user: NewOrExistingUser) {
    enrolledUsers.value[itemId].push(user)
  }

  function unenrollUserFromItem(itemId: number, user: NewOrExistingUser) {
    const index = findIndex(enrolledUsers.value[itemId], (u: NewOrExistingUser) => u.email === user.email)
    if (index !== -1) {
      enrolledUsers.value[itemId].splice(index, 1)
    }
  }

  async function updateEnrollment() {
    let enrollments: ItemEnrollment[] = []

    forEach(enrolledUsers.value, (val, key) => {
      enrollments.push({
        item: Number(key),
        users: val,
      })
    })

    const step = getStep('participants')

    try {
      step.loading = true
      enrolledUsersConflict.value = false
      const response = await api.updateEnrollment(enrollments)
      cart.value = response.data.data
    } catch (e: any) {
      if (e instanceof AxiosError && e.response?.status === 409) {
        enrolledUsersConflict.value = e.response?.data.error?.metadata || false
      }
    } finally {
      step.loading = false
    }
  }

  function submitEnrollment(id: string) {
    next(id)
  }

  //
  // Payment
  //

  const canUseAddress = computed(() => {
    return (
      paymentMethodForm.value?.invoice.address.line1 !== null &&
      paymentMethodForm.value?.invoice.address.line1 !== '' &&
      paymentMethodForm.value?.invoice.address.city !== null &&
      paymentMethodForm.value?.invoice.address.city !== ''
    )
  })

  const canUseCard = computed(() => {
    if (paymentMethod.value !== 'card') {
      return false
    }

    return paymentMethodForm.value.card.valid
  })

  const canUseInvoice = computed(() => {
    if (paymentMethod.value !== 'invoice') {
      return false
    }

    if (cart.value.isFreebie) {
      return true
    }

    return (
      paymentMethodForm.value.invoice.reference !== null &&
      paymentMethodForm.value.invoice.reference !== '' &&
      paymentMethodForm.value.invoice.email !== null &&
      paymentMethodForm.value.invoice.email !== '' &&
      isValidEmail(paymentMethodForm.value.invoice.email)
    )
  })

  function updateInvoiceForm() {
    paymentMethodForm.value.invoice.reference = cart.value.paymentMethodData?.invoiceReference ?? ''
    paymentMethodForm.value.invoice.email = cart.value.paymentMethodData?.invoiceEmail ?? ''
    paymentMethodForm.value.invoice.address.line1 = cart.value.paymentAddress?.line1 ?? ''
    paymentMethodForm.value.invoice.address.line2 = cart.value.paymentAddress?.line2 ?? ''
    paymentMethodForm.value.invoice.address.postcode = cart.value.paymentAddress?.postcode ?? ''
    paymentMethodForm.value.invoice.address.city = cart.value.paymentAddress?.city ?? ''
    paymentMethodForm.value.invoice.address.country =
      cart.value.paymentAddress?.country ?? cart.value.company?.country ?? ''
  }

  function setPaymentMethod(method: PaymentMethod['value']) {
    paymentMethod.value = method
  }

  const canConfirm = computed(() => {
    if (loading.value) {
      return false
    }

    if (paymentMethod.value === 'invoice') {
      return canUseAddress.value && canUseInvoice.value
    }

    return canUseCard.value
  })

  async function confirmOrder() {
    if (loading.value) {
      return
    }

    try {
      paymentProcessed.value = false
      loading.value = 'processing'
      alert.value = undefined

      if (paymentMethod.value === 'card') {
        const confirmationToken = await cardStore.submit()

        if (!confirmationToken) {
          return
        }

        try {
          const { data } = await api.confirm({
            method: paymentMethod.value,
            confirmationToken: confirmationToken.id,
          })

          await cardStore.nextAction(data)
        } catch (err) {
          loading.value = false

          if (!isAxiosError(err)) {
            setAlert(trans('An unknown error occured. Please try again'))
            return
          }

          setAlert(err.response?.data.error)
        }
      }

      if (paymentMethod.value === 'invoice') {
        const { data } = await api.confirm({
          method: paymentMethod.value,
          address: paymentMethodForm.value.invoice.address,
          reference: paymentMethodForm.value.invoice.reference,
          email: paymentMethodForm.value.invoice.email,
        })

        if (!context) {
          window.location.href = data.redirectUrl
        }
      }

      paymentProcessed.value = true
    } catch (error) {
      console.error(error)
      loading.value = false
    } finally {
      clearLocalStorage(cart.value.uuid)
    }
  }

  watch(
    () => cart.value?.company,
    (value) => {
      setInitialEnrolledUserList()
      updateInvoiceForm()
    },
  )

  //get the list actually from BE
  setInitialEnrolledUserList()
  initSteps()
  updateInvoiceForm()

  function initSteps() {
    const id = getActiveStep(cart.value.uuid) ?? 'buyer'

    steps.value.push({
      id: 'buyer',
      label: trans('Buyer'),
      completed: false,
      loading: false,
      content: defineAsyncComponent(() => import('./Steps/Buyer.vue')),
      preview: defineAsyncComponent(() => import('./Steps/BuyerPreview.vue')),
    })

    if (hasItemEnrollmentRequired()) {
      steps.value.push({
        id: 'participants',
        label: trans('Participants'),
        completed: false,
        loading: false,
        content: defineAsyncComponent(() => import('./Steps/Participants.vue')),
        preview: defineAsyncComponent(() => import('./Steps/ParticipantsPreview.vue')),
      })
    }

    steps.value.push({
      id: 'payment',
      label: cart.value.total.gross.cents > 0 ? trans('Payment') : trans('Confirm'),
      completed: false,
      loading: false,
      content: defineAsyncComponent(() => import('./Steps/Payment.vue')),
    })

    let _index = findIndex(steps.value, (el) => el.id === id)

    const numberOfSteps = getStepsNumber(cart.value.uuid)

    if (_index < 0) {
      _index = 0
    }

    if (_index > 1) {
      if (numberOfSteps < steps.value.length) {
        _index = 1
      }
      if (numberOfSteps > steps.value.length) {
        _index = _index - 1
      }
    }

    forEach(steps.value, (step, index) => {
      if (index < _index) {
        step.completed = true
      }
    })

    step.value = steps.value[_index].id

    saveStepsNumber(cart.value.uuid, steps.value.length)
  }

  function getStep(id: string): Step {
    return steps.value.find((step) => step.id === id) as Step
  }

  function next(id: string) {
    const index = findIndex(steps.value, ['id', id])

    steps.value[index]!.completed = true

    if (index + 1 <= steps.value.length) {
      step.value = steps.value[index + 1].id
      saveActiveStep(cart.value.uuid, steps.value[index + 1].id)
    }
  }

  function setAlert(err?: string | Error | StripeError, type: 'danger' | 'success' = 'danger') {
    alert.value = typeof err === 'string' ? err : err?.message
    alertType.value = type
  }

  return {
    cart,
    user,
    step,
    steps,
    loading,
    alert,
    paymentMethod,
    paymentMethodForm,
    isSetCurrentAsEmployerChecked,
    enrolledUsers,
    enrolledUsersConflict,
    canConfirm,
    enrollableItems,
    isSelfEnrolledToAllItems,
    isItemEnrollmentRequiredFullfilled,
    paymentProcessed,

    next,
    reloadCart,
    setAlert,
    submitBuyer,
    updateBuyer,
    setPaymentMethod,
    confirmOrder,
    isItemFullyEnrolled,
    enrollSelfToAll,
    unenrollSlefFromAll,
    enrollUserToItem,
    unenrollUserFromItem,
    isSelfEnrolledToItem,
    enrollSelfToItem,
    isEnrolledSelfToAllDisabled,
    unenrollAllFromItem,
    submitEnrollment,
    updateEnrollment,
  }
})
